'use client';  // Error components must be Client components
// Not translating this error since it's a top level and has no provider embracing it. 

import ErrorGeneric from "./ErrorGeneric";

export default function GlobalError({ error, reset }: { error: Error; reset: () => void }) {  

  return (
    <ErrorGeneric 
      error={error}
      reset={reset}
      status='error'
      title="Something went wrong"
      description="We are working on it"
      buttonTryAgain="Try again"
      buttonBackHome="Go back home"      
    />
  );
}
